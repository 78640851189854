import React from 'react';
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import './Credits.scss'
import RichTextEditor from '../SharedComponents/RichTextEditor/RichTextEditor';
import dompurify from 'dompurify';

const creditConfig = {
    "performer_deal_credit_id": null,
    "credit_type": null,
    "credit_prewrote_text": null,
    "is_delete": "0"
}
class Credits extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            billingText: [],
            allCreditData: null,
            isFetchingCredit: false,
            isPosting: false,
            MTETCredit: { ...creditConfig },
            ETCredit: { ...creditConfig },
            PACredit: { ...creditConfig },
            BNCredit: { ...creditConfig },
            creditText: { ...creditConfig },
            isMTET: false,
            isET: false,
            openNotification: null,
            severity: null,
            edit: false,
        }
    }
    componentDidMount() {
        this.getCreditData();
        this.getBillingText();
    }

    formateCreditData = (creditData) => {
        let mTeTCredit = creditData?.credit?.find(item => item?.credit_type === 'MT_ET')
        let eTCredit = creditData?.credit?.find(item => item?.credit_type === 'ET')
        let paCredit = creditData?.credit?.find(item => item?.credit_type === 'PA')
        let bnCredit = creditData?.credit?.find(item => item?.credit_type === 'BILLING_NOTES')
        this.setState({
            MTETCredit: mTeTCredit ? mTeTCredit : { ...creditConfig },
            ETCredit: eTCredit ? eTCredit : { ...creditConfig },
            PACredit: paCredit ? paCredit : { ...creditConfig },
            BNCredit: bnCredit ? bnCredit : { ...creditConfig },
            isMTET: mTeTCredit ? true : false,
            isET: eTCredit ? true : false,

        })
    }

    getCreditData = () => {
        this.setState({ isFetchingCredit: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealCredit?performer_deal_id=${this.props.dealId}`)
            .then(response => {
                if (response.data.error) {
                    this.setState({ openNotification: response?.data?.message || 'Credit data failed to load', severity: 'error' })
                    return
                }
                this.formateCreditData(response.data[0])
                this.setState({ allCreditData: response.data?.length > 0 ? response.data[0] : [], isFetchingCredit: false, edit: false })
            },
                (err) => {
                    this.setState({ openNotification: 'Credit data failed to load', severity: 'error' })
                })
    }

    validateTextEditorText = (text) => {
        if (text == '<div></div>' || text == '<div><br></div>') {
            return null
        } else {
            return text
        }
    }


    handleSaveCredit = () => {
        this.setState({ isPosting: true })
        let postCreditData = { ...this.state.allCreditData }
        postCreditData.performer_deal_id = this.props?.dealId
        postCreditData.credit = []
        let mTeTCredit = { ...this.state.MTETCredit }
        mTeTCredit.credit_type = 'MT_ET'
        mTeTCredit.credit_prewrote_text = this.validateTextEditorText(mTeTCredit.credit_prewrote_text)
        let eTCredit = { ...this.state.ETCredit }
        eTCredit.credit_type = 'ET'
        eTCredit.credit_prewrote_text = this.validateTextEditorText(eTCredit.credit_prewrote_text)
        let paCredit = { ...this.state.PACredit }
        paCredit.credit_type = 'PA'
        let bnCredit = { ...this.state.BNCredit }
        bnCredit.credit_type = 'BILLING_NOTES'
        bnCredit.credit_prewrote_text = this.validateTextEditorText(bnCredit.credit_prewrote_text)
        if (this.state.isMTET) {
            postCreditData.credit.push(mTeTCredit)
        } else {
            if (mTeTCredit.performer_deal_credit_id) {
                mTeTCredit.is_delete = '1'
                postCreditData.credit.push(mTeTCredit)
            }
        }
        if (this.state.isET) {
            postCreditData.credit.push(eTCredit)
        } else {
            if (eTCredit.performer_deal_credit_id) {
                eTCredit.is_delete = '1'
                postCreditData.credit.push(eTCredit)
            }
        }

        if (bnCredit.credit_prewrote_text) {
            postCreditData.credit.push(bnCredit)
        } else {
            if (bnCredit.performer_deal_credit_id) {
                bnCredit.is_delete = '1'
                postCreditData.credit.push(bnCredit)
            }
        }
        this.postCreditData(postCreditData)
    }

    postCreditData = (postCreditData) => {
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealCredit`, postCreditData)
            .then(response => {
                if (response?.data?.error) {
                    this.setState({ openNotification: response?.data?.message || 'Saving Changes Failed', severity: 'error', isPosting: false })
                } else {
                    this.setState({ openNotification: 'Changes saved successfully', severity: 'success', isPosting: false, edit: false })
                    this.props?.getTalentPerformerDetails(this.props?.dealId)
                    this.getCreditData()
                }
            },
                (err) => {
                    this.setState({ openNotification: 'Saving changes failed', severity: 'error', isPosting: false })
                }
            )
    }

    getBillingText = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=credit_prewrote_text`)
            .then(response => {
                if (response.data.error) {
                    this.setState({ openNotification: response?.data?.message || 'Billing Text failed to load', severity: 'error' })
                    return
                }
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, name: item?.lookup_value }));
                this.setState({ billingText: formattedList });
            },
                (err) => {
                    this.setState({ openNotification: response?.data?.message || 'Billing Text failed to load', severity: 'error' })
                })
    }

    render() {
        return (
            <div className='casterFeatureCreditSection'>
                <NotificationComponent
                    open={this.state.openNotification ? true : false}
                    message={this.state.openNotification || ""}
                    severity={this.state.severity || ""}
                    handleResetNotify={() => this.setState({ openNotification: null, severity: null })} />
                {this.state.isFetchingCredit ? <><CircularProgress /></> :
                    <><MDBRow>
                        <MDBCol md={10}></MDBCol>
                        {this.state.edit && <MDBCol md={1} className='d-flex justify-content-end'>
                            <BasicButton
                                text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : 'Save'}
                                icon={"save"}
                                onClick={this.handleSaveCredit}
                                variant="contained"
                                type="inline" />
                        </MDBCol>}
                        {this.state.edit && <MDBCol md={1} className='d-flex justify-content-end'>
                            <BasicButton
                                text="Cancel"
                                variant="outlined"
                                type="inline"
                                icon="times"
                                onClick={() => this.setState({ edit: false }, () => {
                                    this.formateCreditData(this.state.allCreditData)
                                })} />
                        </MDBCol>}
                        {!this.state.edit && !this.props?.summary && <MDBCol md={2} className='d-flex justify-content-end'>
                            <BasicButton
                                text='Edit'
                                icon={"pen"}
                                onClick={() => this.setState({ edit: true })}
                                variant="contained"
                                type="inline" />
                        </MDBCol>}
                    </MDBRow>
                        {this.props?.summary && this.state.allCreditData?.length === 0 ? <div>No Credits Entered</div> : <MDBRow>
                            <MDBCol md={2}>
                                {this.state.edit ?
                                    <BasicCheckbox
                                        label={"Main Title / End Title"}
                                        checked={this.state.isMTET}
                                        onChange={(e) => this.setState({ isMTET: e.target.checked, isET: false })}
                                        align="top"
                                        id={'performerCreditMTETCheckbox'}
                                    /> :
                                    <div ><MDBIcon className={this.state.isMTET ? 'greenText' : 'redText'} icon={this.state.isMTET ? 'check-circle' : 'times-circle'}></MDBIcon><span>Main Title / End Title</span></div>
                                }
                                {this.state.edit ? <BasicCheckbox
                                    label={"End Title"}
                                    checked={this.state.isET}
                                    onChange={(e) => this.setState({ isET: e.target.checked, isMTET: false })}
                                    align="top"
                                    id={'performerCreditETCheckbox'}
                                /> :
                                    <div ><MDBIcon className={this.state.isET ? 'greenText' : 'redText'} icon={this.state.isET ? 'check-circle' : 'times-circle'}></MDBIcon><span>End Title</span></div>
                                }


                            </MDBCol>
                            <MDBCol md={10}>
                                {this.state?.isMTET && !this.state?.isET && <span><b>Main Title / End Title Credits</b></span>}
                                {this.state?.isET && !this.state?.isMTET && <span><b>End Title Credits</b></span>}
                                {!this.state?.isET && !this.state?.isMTET && <span className='mt-2'><b>Billing Text</b></span>}
                                {!this.state.edit && this.state.MTETCredit?.credit_prewrote_text ? <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(this.state.MTETCredit?.credit_prewrote_text) }} /> : !this.state.edit && this.state.ETCredit?.credit_prewrote_text ? <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(this.state.ETCredit?.credit_prewrote_text) }} /> : !this.state.edit && this.state.BNCredit?.credit_prewrote_text ? <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(this.state.BNCredit?.credit_prewrote_text) }} /> : <div></div>}
                                {this.state.edit &&
                                    <>
                                        <SelectField
                                            placeHolderText='-- Select PreWrote Credits --'
                                            options={this.state.billingText || []}
                                            onChange={(e) => {
                                                let selectedtext = this.state.billingText?.find(item => item?.value === e.target.value)?.label
                                                let creditTextTemp = this.state?.isMTET ? { ...this.state.MTETCredit } : this.state?.isET ? { ...this.state.ETCredit } : { ...this.state.BNCredit }
                                                creditTextTemp.credit_prewrote_text = creditTextTemp.credit_prewrote_text ? creditTextTemp.credit_prewrote_text + '<div>' + selectedtext + '</div>' :'<div>' + selectedtext + '</div>'
                                                this.state?.isMTET ? this.setState({ MTETCredit: creditTextTemp }) : this.state?.isET ? this.setState({ ETCredit: creditTextTemp }) : this.setState({ BNCredit: creditTextTemp })
                                            }}
                                        />
                                        <RichTextEditor id={`creditRichTextMTETcreditNotes`}
                                            onChange={(content = null, delta = null, source = null, editor, variantToAdd = null) => {
                                                let creditTextTemp = this.state?.isMTET ? { ...this.state.MTETCredit } : this.state?.isET ? { ...this.state.ETCredit } : { ...this.state.BNCredit }
                                                creditTextTemp.credit_prewrote_text = editor.getHTML();
                                                // creditTextTemp.credit_prewrote_text = (!this.state?.isMTET && !this.state?.isET) ? editor.getHTML():null;
                                                this.state?.isMTET ? this.setState({ MTETCredit: creditTextTemp }) : this.state?.isET ? this.setState({ ETCredit: creditTextTemp }) : this.setState({ BNCredit: creditTextTemp })
                                            }}
                                            value={this.state?.isMTET ? (this.state.MTETCredit?.credit_prewrote_text) : this.state?.isET ? (this.state.ETCredit?.credit_prewrote_text) :  (this.state.BNCredit?.credit_prewrote_text)} />
                                    </>
                                }

                            </MDBCol>
                        </MDBRow>}
                    </>}
            </div>
        );
    }
}


export default Credits;